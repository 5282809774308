@import "vars";
@import "custom";
@import "flex";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $title-font;
  //font-weight: 700;
  scroll-behavior: smooth;
  letter-spacing: 0.75px;
}

select {
  outline: none;
}

.main-line-height {
  line-height: 34px;
  max-width: 2100px;
}

.font-bolder {
  font-weight: 1000;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 600;
}

.font-light {
  font-weight: 400;
}

button {
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    background-color: #1e476c;
  }

  &:focus {
    outline: none;
  }
}

.index-1 {
  z-index: -5 !important;
}

.index1 {
  z-index: 1;
}

.text-white {
  color: white;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-center {
  text-align: center;
}

.sticky {
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 1;
}

.full-width {
  width: 100%;
}

.dynamic-x-padding {
  padding-left: 16rem;
  padding-right: 16rem;

  @media (max-width: 1919px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }

  @media (max-width: 821px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.dynamic-y-padding {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (max-width: 1919px) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  @media (max-width: 414px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

img {
  width: 100%;
  height: auto;
  // object-fit: contain;
}

.img-cover {
  img {
    object-fit: cover !important;
  }
}

.check {
  border: 2px solid red;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #4e4b66;
}

a:hover {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.bg-gradient {
  background-image: $bg-gradient;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-ternary {
  background-color: $ternary-color;
}

.bg-white {
  background-color: white;
}

.cursor-none {
  cursor: none;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-xl {
  font-size: $xl;
}

.text-lg {
  font-size: $lg;
}

.text-md {
  font-size: $md;
}

.cursor-pointer {
  cursor: pointer;
}

.text-gradient {
  color: $bg-gradient;
  color: red;
}

input {
  border: none;
  font-size: 1.2rem;
  padding: 0.5rem;

  &:focus {
    outline: none;
  }
}