@import "vars";

.primary-button {
  background: $primary-color;
  border-radius: 40px;
  color: white;
  //font-family: $title-font;
  width: 150px;
  height: 3.2rem;

  font-size: 1rem;
  font-weight: bold;
}

.select {
  position: relative;
}

select {
  padding: 7px 40px 7px 12px;
  width: 100%;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
  color: #143d5e;
}

option {
  color: #153d5f;
}

.show-hamburger {
  img {
    height: 30px;
  }
}

.logo-container {
  img {
    height: 2.5rem;
    width: 12rem;
  }
}

.nav-padding {
  padding: 0.8rem 0;
}

.hero-section-img-container {
  height: auto;
  width: auto;

}

.padding-top-hero-section {
  padding: 0.8rem 0;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: 1rem;
}

.top-2 {
  top: 2.5rem;
}



.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.header {
  font-size: 80px;
  color: black;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.hero-bottom-margin {
  bottom: 18vh;
  padding: 0 2rem 0 5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-50 {
  padding-bottom: 0.5rem;
}

.bg{
  background-color: #F1FFFB;
   
   width: 100%;
  }

.privacyimg{
  height:15rem;
  width: auto;
}

.pl-3 {
  padding-left: 1.5rem;
}
.pl-4 {
  padding-left: 2.5rem;
}
.pl-5 {
  padding-left: 3.5rem;
}
.mr-10 {
  margin-left: 16rem;
}
.ml-10 {
  margin-right: 16rem;
}
.pb-1 {
  padding-bottom: 1rem;
}

.text-sm{
  font-size: 13px;
}

.pt-1 {
  padding-top: 0.8rem;
}



.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-10 {
  padding-right: 10rem;
}

.step-to-build-container {
  padding: 5rem 0;
}

.hero-secondary-img {
  img {
    height: 4rem;
    width: 4rem;
  }
}

.down-arrow-img-container {
  height: 3rem;
  width: 3rem;
}

.dynamic-hero-section-small-screen {
  display: none;
}

.profession-img-container {
  img {
    height: 650px;
  }
}

.profession-bitsquare-img-container {
  img {
    height: 35px;
  }
}

.why-connect-img-container {
  img {
    height: auto;
    width: auto;
  }
}

.connect-icons {
  height: 50px;
  width: 30px;
  margin-right: 1rem;
  padding-top: 0.5rem;
}

.connect-line-height {
  line-height: 25px !important;
}

.becoming-square-container {
  border-radius: 16px;
  padding: 50px 138px;
}

.email-container {
  padding: 0.6rem;

  background-color: white;

  width: 45rem;
  border-radius: 32px;

  .input-width {
    width: 75%;
  }
}

.show-hamburger {
  display: none;
}

@media (max-width: 1800px) {
  .hero-bottom-margin {
    bottom: 18vh;
    padding: 0 5rem 0 7rem;
  }
}

//large screens
@media (max-width: 1920px) {
  .hero-section-img-container {
    width: 40vw;
  }

  .hero-bottom-margin {
    bottom: 18vh;
    padding: 0 1rem 0 3rem;
  }

  .padding-top-hero-section {
    padding: 0.5rem 0;
  }

  .email-container {
    width: 45rem;

    //.input-width {
    //  width: 83%;
    //}
  }
}

// @media (max-width: 800px){
//   .profession-img-container{
//     padding-top: 1rem;
//   }
// }
@media (max-width: 1600px) {
  .profession-img-container {
    padding-top: 1rem;
  }

  .profession-img-container img {
    height: 560px;
  }
}

// small desktop
@media (max-width: 1400px) {
  .mr-10 {
  margin-left: 13rem;
}
.ml-10 {
  margin-right: 13rem;
}
  .text-xl {
    font-size: 28px !important;
}
.text-md {
    font-size: 16px !important;
}
.text-lg {
    font-size: 22px !important;
}
  .hero-bottom-margin {
    bottom: 17vh;
    padding: 0px 2rem 0 3rem;
  }
.pr-10 {
    padding-right: 8rem;
}
  .first-text {
    top: 2.5rem;
  }

  .padding-top-hero-section {
    padding: 0;
  }
  .why-connect-img-container {
  img {
    height: auto;
    width: auto;
  }
}
}

@media (max-width: 1365px) {
  .hero-bottom-margin {
    bottom: 17vh;
    padding: 0px 2rem 0 2rem;
  }
  .pr-10 {
    padding-right: 3rem;
}

  img {
    width: 100%;
    height: 93%;
  }

  .first-text {
    top: 3rem;
  }

  .padding-top-hero-section {
    padding: 0;
  }
}

//ipad air
@media (max-width: 1279px) {
  

  .subbtn {
    display: none;
  }

  .hero-section-img-container {
    height: 60vh;
    width: 80vw;
  }

  .connect-with-bitsquare {
    padding-right: 0;
  }

  .dynamic-hero-section-initial {
    display: none;
  }

  .dynamic-hero-section-small-screen {
    display: block;
  }

  .padding-top-hero-section {
    padding: 1rem 0;
  }

  .profession-img-container {
    img {
      display: none;
    }
  }

  .step-to-build-container {
    padding: 3rem 0;
  }

  .why-connect-img-container {
    display: none;
  }

  .connection-main-text {
    text-align: center;
    padding-bottom: 3rem;
  }

  .becoming-square-container {
    padding: 30px 70px;
  }
   .text-sm{
  font-size: 9px;
}

  .email-container {
    //padding: .8rem;
    //
    //background-color: white;

    //width: 100%;
    //border-radius: 32px;
    //
    //.input-width {
    //  width: 68%;
    //}
  }
}

//ipad mini

@media (max-width: 1200px) {
  .email-container {
        width: 39rem;
    
  }
  .text-sm{
  font-size: 9px;
}
  .subbtn {
    display: none;
  }

  .hero-section-img-container {
    width: 80vw;
  }

  .profession-img-container {
    padding-top: 1rem;
  }

  .text-xl {
    font-size: 26px !important;
  }

  .text-lg {
    font-size: 18px !important;
  }

  .text-md {
    font-size: 14px !important;
  }

  .email-container {
    //width: 100%;
    //
    //.input-width {
    //  width: 64%;
    //}
  }

  .show-hamburger {
    display: block;
  }

  .show-nav-button {
    display: none;
  }

  .becoming-square-container {
    border-radius: 16px;
    padding: 30px 50px;
  }
  .mr-10 {
  margin-left: 5rem;
}
.ml-10 {
  margin-right: 5rem;
}
}

//mobile screens
@media (max-width: 738px) {
  .no-wrap {
  flex-wrap: wrap !important;
}
.pt-2 {
  padding-top: 1rem !important;
}
 .mr-10 {
  margin-left: 2.5rem;
}
.ml-10 {
  margin-right: 2.5rem;
}
  input {
    border: none;
    font-size: 1.2rem;
    padding: 0.8rem !important;
    border-radius: 30px;
  }

  .subbtn {
    display: none;
  }

  .select {
    width: 32vw;
    font-size: 10px;
  }

  .nav-padding {
    padding: 0.8rem 0;
  }

  .hero-section-img-container {
    display: none;
    //height: 50vh;
    //width: 90vw;
  }

  .profession-secondary-section {
    padding-top: 1rem;
  }

  .profession-img-container {
    padding-top: 1rem;

    img {
      height: 250px;
    }
  }

  .profession-bitsquare-img-container {
    padding-right: 0.5rem;

    img {
      width: 100px;
      height: 28px;
    }
  }

  .step-to-build-container {
    padding: 1rem 0;
  }

  .dynamic-connect-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .connect-sub-section {
    width: 100%;
    padding-right: 0;
    padding-bottom: 2rem;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    padding-left: 20vw;
  }

  .bitsquare-text {
    padding-left: 22vw;
  }

  .customer-text {
    padding-left: 4vw;
  }

  .email-container {
    //width: 100%;

    .input-width {
      width: 100%;
    }

    .subscribe-button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .becoming-square-container {
    border-radius: 16px;
    padding: 30px 30px;
  }

  .logo-container img {
    height: 2rem;
    width: 8rem;
  }

  .text-xl {
    font-size: 16px !important;
  }

  .text-lg {
    font-size: 16px !important;
  }

  .text-md {
    font-size: 11px !important;
  }
  .text-sm {
    font-size: 8px !important;
  }

  .font-mobile {
    font-size: 14px;
  }

  .email-container {
    width: 17rem;
    background-color: #153D5F !important;
  }

  .primary-button {
    background-color: #50B4A1;
    width: 100%;
  }

  .subBtn {
    padding-top: 1rem;
    width: 100%;
  }
 .show-hamburger {
    display: block;
        width: 1.5rem;
}
.col-6{
 grid-column: span 12 / span 12 !important;
}
.privacyimg{
  height:10rem;
  width: auto;
  padding-top: 1rem;
}


}
@media (max-width:280px){
  .no-wrap {
  flex-wrap: wrap !important;
}
.show-hamburger {
    display: block;
        width: 1.2rem;
}
.email-container {
    width: 17rem;
    background-color: #153D5F !important;
  }
  .hero-secondary-img img {
    height: 3rem;
    width: 4rem;
}
.email-container {
    width: 11rem;
    background-color: #153D5F !important;
}
.logo-container img {
    height: 1.5rem;
    width: 5rem;
}
.text-lg {
    font-size: 9px !important;
}
.font-mobile {
    font-size: 10px;
}
.connect-icons {
    height: 30px;
    width: 30px;
    margin-right: 1rem;
    padding-top: 0.5rem;
}
input {
    border: none;
    font-size: 1rem;
    padding: 0.5rem;
}
primary-button {
  height: 3rem;
  font-size: 0.8rem;
  font-weight: bold;
}
    
    
.footer-text{
  font-size: 0.7rem;
}
.pb-2 {
    padding-bottom: 1rem;
}
}
